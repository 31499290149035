import { LogLevel } from '@azure/msal-browser';

const isDevlopment = false;

// const uatUrl = 'https://uspopsreporting-uat.optum.com/';
const devUrl = 'https://usplandingzone-dev.optum.com/';
// const prodUrl = 'https://uspopsreporting.uhc.com/';

const redirectUri = isDevlopment ? 'http://localhost:3004' : devUrl;

/* eslint no-console: ["error", { allow: ["warn", "error", "info", "debug", "log"] }] */

export const msalConfig = {
  auth: {
    clientId: '66cd51d0-0d66-4a64-aad6-882a064c3302',
    authority:
      'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421',
    redirectUri,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const silentRequest = {
  scopes: ['user.read'],
  loginHint: 'example@domain.net',
};
